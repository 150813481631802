import React from 'react';
import PropTypes from 'prop-types';
import { Eyebrow, SectionIntro } from '@web/atoms';
import { CtaButton } from '@web/molecules';
import { StyledSimplifiedHero, ButtonWrapper } from './styles.js';

export const SimplifiedHero = ({
  heading,
  description,
  backgroundColor,
  link,
  buttonVariant,
  level,
  eyebrow,
}) => {
  return (
    <StyledSimplifiedHero backgroundColor={backgroundColor}>
      {eyebrow && <Eyebrow textAlign="center">{eyebrow}</Eyebrow>}
      <SectionIntro
        headline={heading}
        subheadline={description}
        headingLevel={level}
      />
      {link && (
        <ButtonWrapper backgroundColor={backgroundColor}>
          <CtaButton href={link.target} variant={buttonVariant}>
            {link.text}
          </CtaButton>
        </ButtonWrapper>
      )}
    </StyledSimplifiedHero>
  );
};

SimplifiedHero.displayName = 'SimplifiedHero';

SimplifiedHero.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  backgroundColor: PropTypes.string,
  link: PropTypes.shape({
    text: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
  }),
  buttonVariant: PropTypes.string,
  level: PropTypes.string,
  eyebrow: PropTypes.string,
};
