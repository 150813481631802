'use client';
import React from 'react';
import { List } from '@web/molecules';
import blogImage1 from '../images/account/BlogImage1.png';
import blogImage2 from '../images/account/BlogImage2.png';
import blogImage3 from '../images/account/BlogImage3.png';
import foodBowl from '../images/FoodBowl.png';
import freshRecipesImage from '../images/recipes/types/all-fresh@2x.jpg';
import mixedRecipesImage from '../images/recipes/types/mixed@2x.jpg';
import dryRecipesImage from '../images/recipes/types/baked@2x.jpg';
import halfFreshBowl from '../images/recipes/types/half@2x.jpg';
import JerkyTreatChickenAppleImage from '../images/our-food/addons/JerkyTreats-ChickenApple.jpg';
import JerkyTreatBeefSweetPotatoImage from '../images/our-food/addons/JerkyTreats-BeefSweetPotato.jpg';
import ZoomiesImage from '../images/our-food/addons/Supplements-Zoomies.png';
import ChillPillsImage from '../images/our-food/addons/Supplements-ChillPills.png';
import googlePlayStoreButtonImage from '../images/account/google-play-button.svg';
import appleStoreButtonImage from '../images/account/apple-button.svg';
import ollieAppDesktopImage from '../images/account/inside-the-app-desktop-image.png';
import ollieAppMobileImage from '../images/account/inside-the-app-mobile-image.png';
import appQRCode from '../images/account/qr-code.svg';
import { ADDON_SKUS } from './constants';

export const ACTIVE_STATUS = 'Active';

export const ADD_PET_FLOW_KEY = 'ADD_PET_FLOW';

export const ADD_PET_SUBSCRIPTION_KEY = 'ADD_PET_SUBSCRIPTION';

export const LEARN_MORE = {
  btnLabel: 'Learn More',
  heading: 'What goes in Ollie products',
  body: '100% real food, no by-products or funky fillers. We gently cook our food in small batches and deliver it for free to your door.',
  href: '/recipes/',
  img: {
    src: foodBowl,
    alt: 'Food Bowl',
    height: 92,
    width: 100,
  },
};

export const HEADLINE = 'Welcome back, {{name}}!';
export const SUBHEADLINE =
  'Keep tabs on your subscription and edit info for {{petName}}.';
export const DOG_SELECTOR_LABEL = 'View info for:';
export const ADD_PUP_BTN_URL = 'account/add-pup';
export const ADD_PUP_BTN_LABEL = 'Add another pup';
export const YOUR_PUP = {
  title: 'Your pup',
  image: {
    src: 'https://a.storyblok.com/f/89894/102x110/81f1273ac8/petting-dog.png',
    alt: 'doggo',
    width: 500,
    height: 300,
  },
  buttonUrlTarget: '/account/edit-pup',
  buttonLabel: "Edit pup's details",
  reactivateButtonLabel: 'Reactivate membership now',
  hasActiveSubscription: true,
  reactivateUrlTarget: '/account/reactivate-pup/summary',
  feedingGuidelinesLabel: 'View {{pupName}}’s feeding guidelines',
  inactiveMembershipText: 'Membership inactive',
};

export const EMPTY_PET = {
  breeds: [],
  allergies: [],
  name: '',
};

export const PORTION_SECTION_MODAL_CONTENT = {
  title: 'INSIDE THE APP',
  subtitle: "Customize your pup's meal plan and more with the Ollie app.",
  body: 'Manage your box',
  list: {
    heading: '',
    icon: 'checkmarkSimple',
    items: [
      { body: 'Manage your box', component: 'list_item', heading: '' },
      { body: 'Adjust your portion', component: 'list_item', heading: '' },
      { body: 'Get a Health Screening', component: 'list_item', heading: '' },
      { body: 'Track orders and more', component: 'list_item', heading: '' },
    ],
  },
  //add images
  desktopImage: {
    src: ollieAppDesktopImage,
    alt: '',
  },
  mobileImage: {
    src: ollieAppMobileImage,
    alt: '',
  },
  cta: [
    {
      component: 'social_button',
      image: {
        src: appleStoreButtonImage,
        alt: 'Download on the App Store',
      },
      href: 'https://apps.apple.com/us/app/ollie-human-grade-dog-food/id1614301164',
      openNewTab: true,
    },
    {
      component: 'social_button',
      image: {
        src: googlePlayStoreButtonImage,
        alt: 'Get it on Google Play',
      },
      href: 'https://play.google.com/store/apps/details?id=com.ollie.mobile_app',
      openNewTab: true,
    },
  ],
  qrCode: {
    src: appQRCode,
    alt: '',
  },
  qrExplanation: 'Scan code below to download the app',
};

export const BOX_MANAGEMENT_NAV = {
  label: 'Manage {{pupName}}’s box',
  shippingDate: ['June 16, 2021'],
  frequency: ['Every 2 weeks'],
  shippingInfo: ['Kyle Mitchell', '62 Pine Hill Rd', 'Southborough, MA 01772'],
  paymentInfo: [
    'Card: •••• •••• •••• 1234',
    'Billing zip: 12136',
    'Exp. 3/12/23',
  ],
  product: {
    variable: 'mix',
    recipes: {
      dry: ['Beef', 'Chicken'],
      fresh: ['Beef', 'Chicken', 'Lamb', 'Turkey'],
    },
  },
  mealPrep: ['A mixed bowl'],
  addonsInfo: {
    showAddons: false,
    subscriptionId: '1',
  },
};

export const BOX_MANAGEMENT_NAV_WITH_PORTION_SECTION = {
  ...BOX_MANAGEMENT_NAV,
  showPortionSection: true,
  portionSectionText: ['1/2 pack fresh/day (214 calories)'],
  portionSectionModalContent: PORTION_SECTION_MODAL_CONTENT,
};

export const BOX_MANAGEMENT_NAV_NO_PAYMENT_METHOD = {
  label: 'Manage {{pupName}}’s box',
  shippingDate: ['June 16, 2021'],
  frequency: ['Every 2 weeks'],
  shippingInfo: ['Kyle Mitchell', '62 Pine Hill Rd', 'Southborough, MA 01772'],
  paymentInfo: [],
  product: {
    variable: 'mix',
    recipes: {
      dry: ['Beef', 'Chicken'],
      fresh: ['Beef', 'Chicken', 'Lamb', 'Turkey'],
    },
  },
  mealPrep: ['A mixed bowl'],
  addonsInfo: {
    showAddons: false,
    subscriptionId: '1',
  },
};

export const BLOGS = [
  {
    topic: 'DOG NUTRITION',
    title: {
      position: 'left',
      typography: 'body',
      headingText: 'The 10 Best Ingredients for Dogs',
      headingLevel: 'h6',
    },
    date: '21.JUN.2021',
    image: {
      src: blogImage1,
      alt: 'nicely plated fresh ingredients on a pink tablecloth',
      width: 335,
      height: 190,
    },
    blogLink: 'https://blog.myollie.com/10-healthiest-dog-food-ingredients/',
    backgroundColor: 'background.lightest',
  },
  {
    topic: 'TRAINING ADVICE',
    title: {
      position: 'left',
      typography: 'body',
      headingText: 'What to Do if Your Dog Eats Too Fast and Then Throws Up',
      headingLevel: 'h6',
    },
    date: '28.FEB.2021',
    image: {
      src: blogImage2,
      alt: 'doggo eating their food with great enthusiasm and speed',
      width: 335,
      height: 190,
    },
    blogLink:
      'https://blog.myollie.com/how-to-slow-down-a-dog-who-eats-too-fast/',
    backgroundColor: 'background.lightest',
  },
  {
    topic: 'DOG HEALTH',
    title: {
      position: 'left',
      typography: 'body',
      headingText: "What your Dog's Poop Says About Their Health",
      headingLevel: 'h6',
    },
    date: '20.MAY.2021',
    image: {
      src: blogImage3,
      alt: 'drawing of dog waste bag dispenser with the words "Party Pooper" written on it',
      width: 335,
      height: 190,
    },
    blogLink:
      'https://blog.myollie.com/what-your-dogs-poop-says-about-their-health/',
    backgroundColor: 'background.lightest',
  },
];

export const BLOG_INFO = {
  blogHeading: 'From our blog',
  fontColor: 'hero.blueberry',
  blogs: BLOGS,
};
export const DISCOUNT_AMOUNT = 40;

const FRESH_DESCRIPTION_LIST = [
  {
    heading: 'List item 1',
    body: 'Human-grade ingredients',
  },
  {
    heading: 'List item 2',
    body: "100% of your pup's recommended daily calories from fresh meals.",
  },
  {
    heading: 'List item 3',
    body: 'Health benefits you can see and your pup can feel.',
  },
];
const HALF_FRESH_DESCRIPTION_LIST = [
  {
    heading: 'List item 1',
    body: 'Human-grade ingredients',
  },
  {
    heading: 'List item 2',
    body: "50% of your pup's recommended daily calories",
  },
  {
    heading: 'List item 3',
    body: 'Experience the benefits of a fresh food diet while keeping costs down',
  },
];
const MIXED_DESCRIPTION_LIST = [
  {
    heading: 'List item 1',
    body: 'Human-grade ingredients',
  },
  {
    heading: 'List item 2',
    body: "100% of your pup's recommended daily calories from mixed meals.",
  },
  {
    heading: 'List item 3',
    body: 'Tailored to your pup’s unique preferences.',
  },
];
const BAKED_DESCRIPTION_LIST = [
  {
    heading: 'List item 1',
    body: 'Easy to store, serve, and take on the go',
  },
  {
    heading: 'List item 2',
    body: 'Up to 38% less expensive than an all Fresh diet',
  },
  {
    heading: 'List item 3',
    body: '100% human-grade',
  },
];

export const MEAL_PREP_TYPES = [
  {
    title: 'FULL FRESH PLAN',
    label: 'Select',
    value: 'fresh',
    description: (
      <>
        <p>
          The pinnacle of nutrition in the form of fresh, human-grade meals.
        </p>
        <List items={FRESH_DESCRIPTION_LIST} icon="checkmark" />
      </>
    ),

    img: {
      src: freshRecipesImage.src,
      alt: 'fresh recipe',
    },
    pricingInfo: {
      // eslint-disable-next-line no-template-curly-in-string
      price: '{{freshPrice}}',
    },
    benefit: 'recommended',
    shouldShowMoreDetails: false,
    unavailableText:
      'Based on your answers, this meal plan is unavailable for {{pupName}}. Please contact our Canine Care team if you have any questions.',
  },
  {
    title: 'MIXED BOWL PLAN',
    label: 'Select',
    value: 'mixed',
    description: (
      <>
        <p>The best of both our Fresh and Baked recipes in one meal.</p>
        <List items={MIXED_DESCRIPTION_LIST} icon="checkmark" />
      </>
    ),
    img: {
      src: mixedRecipesImage.src,
      alt: 'mixed recipe',
    },
    pricingInfo: {
      // eslint-disable-next-line no-template-curly-in-string
      price: '{{mixedPrice}}',
    },
    benefit: 'great variety',
    shouldShowMoreDetails: false,
    unavailableText:
      'Our Baked meals have become so popular that we’ve unfortunately sold out. We expect to have these plans back in stock before the end of the year. Please contact our Canine Care team if you have questions.',
  },
  {
    title: 'HALF FRESH PLAN',
    label: 'Select',
    value: 'half_fresh',
    description: (
      <>
        <p>
          A nutritious boost of Fresh food to add to your pup's current food.
        </p>
        <List items={HALF_FRESH_DESCRIPTION_LIST} icon="checkmark" />
      </>
    ),

    img: {
      src: halfFreshBowl.src,
      alt: 'half fresh recipe',
    },
    pricingInfo: {
      // eslint-disable-next-line no-template-curly-in-string
      price: '{{halfFreshPrice}}',
    },
    benefit: 'budget friendly',
    callout: 'new',
    shouldShowMoreDetails: false,
    unavailableText:
      'Based on your answers, this meal plan is unavailable for {{pupName}}. Please contact our Canine Care team if you have any questions.',
  },
  {
    title: 'FULL BAKED PLAN',
    label: 'Select',
    value: 'dry',
    description: (
      <>
        <p>
          Real meat and veggies baked to preserve more nutrients and flavor than
          kibble.
        </p>
        <List items={BAKED_DESCRIPTION_LIST} icon="checkmark" />
      </>
    ),
    img: {
      src: dryRecipesImage.src,
      alt: 'dry recipe',
    },
    pricingInfo: {
      // eslint-disable-next-line no-template-curly-in-string
      price: '{{bakedPrice}}',
    },
    benefit: 'best value',
    shouldShowMoreDetails: false,
    unavailableText:
      'Our Baked meals have become so popular that we’ve unfortunately sold out. We expect to have these plans back in stock before the end of the year. Please contact our Canine Care team if you have questions.',
  },
];

export const COLLAPSED_MEAL_PREP_TYPES = [
  {
    title: 'GENTLY BAKED',
    label: 'Select Baked',
    value: 'dry',
    description: (
      <>
        <p>Gently baked real meat and veggies.</p>
      </>
    ),
    img: {
      src: dryRecipesImage.src,
      alt: 'dry recipe',
    },
    pricingInfo: {
      // eslint-disable-next-line no-template-curly-in-string
      price: '${{bakedPrice}}/week',
    },
    shouldShowMoreDetails: false,
    unavailableText:
      'Our Baked meals have become so popular that we’ve unfortunately sold out. We expect to have these plans back in stock before the end of the year. Please contact our Canine Care team if you have questions.',
  },
];

export const STAGING_TRACKING_URL =
  'https://myollie.narvar.com/myollie/tracking/fedex?carrier=fedex&tracking_numbers=2473983&service=HD&ozip=18706&dzip=08742-4632&origin_country=US&destination_country=US&order_number=2000007954&ship_date=2022-12-19';

export const DENTAL_CHEWS_SKUS = [
  'SUPP-DENT-XS',
  'SUPP-DENT-SM',
  'SUPP-DENT-MD',
  'SUPP-DENT-LG',
];

export const supplementsModalDropdownValues = {
  dropdowns: [
    {
      name: 'quantity',
      label: 'Quantity',
      options: [
        { label: '1 Jar', value: 1 },
        { label: '2 Jars', value: 2 },
      ],
    },
    {
      name: 'frequency',
      label: 'Frequency',
      options: [
        { label: 'Every box', value: 1 },
        { label: 'Every other box', value: 2 },
      ],
    },
  ],
  addButtonLabel: 'Add',
  removeButtonLabel: 'Remove',
  doneButtonLabel: 'Done',
};

export const treatsModalDropdownValues = {
  dropdowns: [
    {
      name: 'quantity',
      label: 'Quantity',
      options: [
        { label: '1 Bag', value: 1 },
        { label: '2 Bags', value: 2 },
      ],
    },
    {
      name: 'frequency',
      label: 'Frequency',
      options: [
        { label: 'Every box', value: 1 },
        { label: 'Every other box', value: 2 },
      ],
    },
  ],
  addButtonLabel: 'Add',
  removeButtonLabel: 'Remove',
  doneButtonLabel: 'Done',
};

export const allTreats = [
  {
    value: 'Chicken and Apple Jerky Treats',
    title: 'Chicken and Apple Recipe',
    productName: 'Chicken and Apple Jerky',
    sku: ADDON_SKUS.chickenjerky,
    subtitle: 'Jerky treats',
    product_available: true,
    product_recommended: false,
    details:
      'Our 100% all-natural jerky strips are made with simple, human-grade ingredients to keep snack time real and exciting. Our Chicken and Apple Recipe is a tasty and healthy way to reward and treat your pup regularly.',
    nutritionalInformation: [
      {
        label: 'Protein minimum',
        value: '26%',
      },
      {
        label: 'Fat minimum',
        value: '16%',
      },
      {
        label: 'Fiber maximum',
        value: '2%',
      },
      {
        label: 'Moisture maximum',
        value: '25%',
      },
    ],
    fullIngredients:
      'Chicken, Oats, Coconut Glycerin, Apple, Sea Salt, Celery Powder',
    size: '1 bag (5oz)',
    pricingInfo: {
      price: '12.00',
      discountedPrice: '9.00',
      saveText: '(Save 25%)',
    },
    price: '12.00',
    discounted_price: '9.00',
    serving:
      "Feed as a treat. Treats should not make up more than 10% of your dog's daily calorie intake.",
    image: {
      src: JerkyTreatChickenAppleImage.src,
      alt: 'Chicken and apple jerky treats',
      width: 500,
      height: 500,
    },
    images: [
      {
        src: JerkyTreatChickenAppleImage.src,
        alt: 'Chicken and apple jerky treats',
        width: 500,
        height: 500,
      },
    ],
    dropdownValues: treatsModalDropdownValues,
    productType: 'TR',
    defaultValues: {
      quantity: 1,
      frequency: 1,
    },
    recurrence: 'null',
  },
  {
    value: 'Beef and Sweet Potato Jerky Treats',
    title: 'Beef and Sweet Potato Recipe',
    productName: 'Beef and Sweet Potato Jerky',
    sku: ADDON_SKUS.beefjerky,
    subtitle: 'Jerky treats',
    product_available: true,
    product_recommended: false,
    details:
      'Our 100% all-natural jerky strips are made with simple, human-grade ingredients to keep snack time real and exciting. Our Beef and Sweet Potato Recipe is a hearty and healthy way to reward and treat your pup regularly.',
    nutritionalInformation: [
      {
        label: 'Protein minimum',
        value: '27%',
      },
      {
        label: 'Fat minimum',
        value: '16%',
      },
      {
        label: 'Fiber maximum',
        value: '2%',
      },
      {
        label: 'Moisture maximum',
        value: '21%',
      },
    ],
    fullIngredients:
      'Beef, Oats, Coconut Glycerin, Sweet Potato, Sea Salt, Celery Powder',
    size: '1 bag (5oz)',
    pricingInfo: {
      price: '12.00',
      discountedPrice: '9.00',
      saveText: '(Save 25%)',
    },
    price: '12.00',
    discounted_price: '9.00',
    serving:
      "Feed as a treat. Treats should not make up more than 10% of your dog's daily calorie intake.",
    image: {
      src: JerkyTreatBeefSweetPotatoImage.src,
      alt: 'Beef and sweet potato jerky treats',
      width: 500,
      height: 500,
    },
    dropdownValues: treatsModalDropdownValues,
    productType: 'TR',
    defaultValues: {
      quantity: 1,
      frequency: 1,
    },
    recurrence: 'null',
  },
];
export const allSupplements = [
  {
    value: 'Chill Pills Calming Supplement',
    title: 'Chill Pills',
    productName: 'Chill Pills',
    sku: ADDON_SKUS.chillpills,
    subtitle: 'Calming formula',
    product_available: true,
    product_recommended: false,
    details:
      "The ingredients in our Chill Pills calming formula are blended to work with a dog's biochemistry in order to help soothe and calm naturally.",
    nutritionalInformation: [
      {
        label: 'Organic Hemp Seed Oil',
        value: '100mg',
      },
      {
        label: 'Ginkgo Biloba Leaf',
        value: '75mg',
      },
      {
        label: 'Passion Flower',
        value: '50mg',
      },
      {
        label: 'Melatonin',
        value: '1mg',
      },
    ],
    fullIngredients: (
      <p>
        <strong>Active Ingredients:</strong> Hemp Seed Oil, Ginko Biloba Leaf,
        Passion Flower, Melatonin
        <br />
        <strong>Inactive Ingredients:</strong> Brewer’s Yeast, Citric Acid (as
        preservative), Flaxseed Meal, Vegetable Glycerin, Palm Fruit Oil
        (Organic, RSPO), Peanut Butter and Banana Flavor (non-allergenic)
        Sunflower Lecithin, Tapioca Starch.
      </p>
    ),
    size: '1 jar (60 chews)',
    pricingInfo: {
      price: '32.00',
      discountedPrice: '16.00',
      saveText: '(Save 50%)',
    },
    price: '32.00',
    discounted_price: '16.00',
    serving:
      'Do not exceed the recommended daily amounts. Suggested Use: Up to 30 lbs. 1 soft chew/day | 31-60 lbs. 2 soft chews/day | 61-90 lbs. 3 soft chews/day | 91 lbs. and Above 4 soft chews/day | This product is intended for intermittent or supplemental use only.',
    image: {
      src: ChillPillsImage.src,
      alt: 'Chill Pills calming supplement',
      width: 500,
      height: 500,
    },
    dropdownValues: supplementsModalDropdownValues,
    productType: 'SP',
    defaultValues: {
      quantity: 1,
      frequency: 1,
    },
    recurrence: 'null',
  },
  {
    value: 'Zoomies Hip and Joint Supplement',
    title: 'Zoomies',
    sku: ADDON_SKUS.zoomies,
    productName: 'Zoomies',
    subtitle: 'Hip and joint formula',
    product_available: true,
    product_recommended: false,
    details:
      'Our Zoomies hip and joint formula helps reduce inflammation and joint pain in dogs of all ages.',
    nutritionalInformation: [
      {
        label: 'Glucosamine HCl (from Shellfish)',
        value: '450mg',
      },
      {
        label: 'Methylsulfonylmethane (MSM)',
        value: '400mg',
      },
      {
        label: 'Ascorbic Acid (Vitamin C)',
        value: '140mg',
      },
      {
        label: 'Chondroitin Sulfate (Porcine)',
        value: '125mg',
      },
      {
        label: 'D-alpha tocopherol acetate (Vitamin E)',
        value: '25 IU',
      },
      {
        label: 'Kelp Powder',
        value: '25mg',
      },
      {
        label: 'Tumeric',
        value: '25mg',
      },
    ],
    fullIngredients: (
      <p>
        <strong>Active Ingredients:</strong> Glucosamine HCl (from shellfish),
        Methylsulfonylmethane (MSM), Vitamin C, Chondroitin Sulfate (porcine),
        Vitamin E, Kelp Powder, Turmeric (Organic)
        <br />
        <strong>Inactive Ingredients:</strong> Brewer’s Yeast, Citric Acid (as
        preservative), Flaxseed Meal, Vegetable Glycerin, Palm Fruit Oil
        (Organic, RSPO), Sunflower Lecithin, Tapioca Flour, Natural Vegetarian
        Turkey Flavor
      </p>
    ),
    size: '1 jar (60 chews)',
    pricingInfo: {
      price: '12.00',
      discountedPrice: '9.00',
      saveText: '(Save 25%)',
    },
    price: '12.00',
    discounted_price: '9.00',
    serving:
      'Do not exceed the recommended daily amounts. Suggested Use: Up to 30 lbs. 1 soft chew/day | 31-60 lbs. 2 soft chews/day | 61-90 lbs. 3 soft chews/day | 91 lbs. and Above 4 soft chews/day | This product is intended for intermittent or supplemental use only.',
    image: {
      src: ZoomiesImage.src,
      alt: 'Zoomies hip and joint supplement',
      width: 500,
      height: 500,
    },
    dropdownValues: supplementsModalDropdownValues,
    productType: 'SP',
    defaultValues: {
      quantity: 1,
      frequency: 1,
    },
    recurrence: 'null',
  },
];
export const ADDON_MODALS = [...allTreats, ...allSupplements];
