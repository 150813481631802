import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage, CtaButton } from '@web/molecules';
import { useReporter } from '../../reporter';
import { SocialButton } from '../SocialButton';
import { ctaButtonClicked, appStoreCTAButtonClicked } from './events';
import {
  Container,
  Content,
  Header,
  BodyText,
  ImageWrapper,
  ButtonWrapper,
} from './styles';

export const ConfirmationBanner = ({ header, description, image, cta }) => {
  const reporter = useReporter();
  return (
    <>
      <Container>
        <ImageWrapper>
          <ResponsiveImage
            src={image.src}
            alt={image.alt}
            fill={true}
            objectFit="cover"
          />
        </ImageWrapper>
        <Content>
          <Header>{header}</Header>
          <BodyText>{description}</BodyText>
          {cta && (
            <ButtonWrapper>
              {cta.map((button, key) =>
                button.component === 'social_button' ? (
                  <SocialButton
                    key={key}
                    {...button}
                    onClick={() =>
                      reporter.tag(appStoreCTAButtonClicked(button.appType))
                    }
                  />
                ) : (
                  <CtaButton
                    key={key}
                    href={button.target}
                    variant={'primary'}
                    onClick={() =>
                      reporter.tag(ctaButtonClicked(button.text, header))
                    }
                  >
                    {button.text}
                  </CtaButton>
                ),
              )}
            </ButtonWrapper>
          )}
        </Content>
      </Container>
    </>
  );
};

ConfirmationBanner.displayName = 'ConfirmationBanner';
ConfirmationBanner.propTypes = {
  header: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  promoPillText: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  cta: PropTypes.array,
};
