import React from 'react';
import PropTypes from 'prop-types';
import { LinkButton } from '@ui/button';
import { ResponsiveImage } from '@web/molecules';
import { Eyebrow } from '@web/atoms';
import { useReporter } from '../../reporter';
import { ctaButtonClicked } from './events';
import {
  Content,
  ButtonContainer,
  ImageContainer,
  StyledSplitScreenPromo,
  ButtonWrapper,
} from './styles.js';

export const SplitScreenPromo = ({
  contentBgColor,
  imageBgColor,
  fontColor,
  image,
  link,
  imageFirst = false,
  content,
  eyebrow,
  ctaButtonColor = 'primary',
}) => {
  const reporter = useReporter();
  return (
    <StyledSplitScreenPromo>
      <Content $fontColor={fontColor} $bgColor={contentBgColor}>
        {eyebrow && <Eyebrow textAlign="center">{eyebrow}</Eyebrow>}
        {content}
        {link && (
          <ButtonContainer>
            <ButtonWrapper $bgColor={contentBgColor}>
              <LinkButton
                variant={ctaButtonColor}
                href={link.target}
                onClick={() =>
                  reporter.tag(
                    ctaButtonClicked(
                      link.text,
                      content?.[0]?.props?.content?.heading_text,
                    ),
                  )
                }
              >
                {link.text}
              </LinkButton>
            </ButtonWrapper>
          </ButtonContainer>
        )}
      </Content>
      <ImageContainer $bgColor={imageBgColor} imageFirst={imageFirst}>
        {image?.src && <ResponsiveImage {...image} />}
      </ImageContainer>
    </StyledSplitScreenPromo>
  );
};

SplitScreenPromo.displayName = 'SplitScreenPromo';

SplitScreenPromo.propTypes = {
  contentBgColor: PropTypes.string,
  fontColor: PropTypes.string,
  image: PropTypes.object,
  imageBgColor: PropTypes.string,
  imageFirst: PropTypes.bool,
  link: PropTypes.shape({
    target: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  content: PropTypes.node,
  eyebrow: PropTypes.string,
  ctaButtonColor: PropTypes.string,
};
