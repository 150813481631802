import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage } from '@web/molecules';
import { Eyebrow } from '@web/atoms';
import {
  StyledSplitPanelBody,
  IconContainer,
  Header,
  Body,
  SocialMediaIcons,
} from './styles.js';

export const SplitPanelBody = ({
  heading,
  icon,
  body,
  socialMediaIcons,
  eyebrow,
  level,
}) => {
  return (
    <StyledSplitPanelBody>
      {icon && (
        <IconContainer>
          <ResponsiveImage
            alt={icon.alt}
            src={icon.src}
            width={icon.width}
            height={icon.height}
          />
        </IconContainer>
      )}
      {eyebrow && <Eyebrow>{eyebrow}</Eyebrow>}
      {heading && <Header as={level}>{heading}</Header>}
      <Body>{body}</Body>
      {socialMediaIcons && (
        <SocialMediaIcons>
          {socialMediaIcons.map((SMIcon, i) => (
            <a key={i} href={SMIcon.url} rel="noreferrer" target="_blank">
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img alt={SMIcon.alt} src={SMIcon.src} />
            </a>
          ))}
        </SocialMediaIcons>
      )}
    </StyledSplitPanelBody>
  );
};

SplitPanelBody.displayName = 'SplitPanelBody';

SplitPanelBody.propTypes = {
  icon: PropTypes.shape({
    alt: PropTypes.string.isRequired,
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    width: PropTypes.string,
    height: PropTypes.string,
  }),
  heading: PropTypes.string,
  body: PropTypes.node,
  socialMediaIcons: PropTypes.arrayOf(
    PropTypes.shape({
      alt: PropTypes.string.isRequired,
      src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
      url: PropTypes.string.isRequired,
    }),
  ),
  eyebrow: PropTypes.string,
  level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
};
