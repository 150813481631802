import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useReporter } from '../../reporter';
import { useExperiment } from '../../experiments';
import { flagshipABContentNotFound } from './events';

export const FlagshipABTest = ({ testName, variants }) => {
  const reporter = useReporter();
  const { value: variantKey } = useExperiment(testName);

  const content = variants[variantKey];
  useEffect(() => {
    if (!content) {
      reporter.tag(
        flagshipABContentNotFound(testName, variantKey || 'Not Found'),
      );
    }
  }, [content, testName, variantKey, reporter]);
  if (!content) {
    return null;
  }
  return <>{content}</>;
};

FlagshipABTest.propTypes = {
  testName: PropTypes.string.isRequired,
  variants: PropTypes.object.isRequired,
};
