'use client';
import styled from 'styled-components';

export const StyledIllustrationPills = styled.div`
  overflow: scroll;
  width: 100%;
  /* Height & spacing equates to 100px */
  height: ${props => props.theme.spacing(25)};
  display: grid;
  align-items: center;
  background-color: ${props => props.theme.colors.get(props.$bgColor)};
`;

export const Grid = styled.div`
  display: grid;
  justify-content: center;
  justify-items: center;
  grid-template-columns: repeat(${props => props.gridAmount}, 1fr);
  gap: ${props => props.theme.spacing(3)};
  margin: auto;
`;

export const MobileWrapper = styled.div`
  display: none;
  @media ${props => props.theme.tabletS} {
    display: block;
  }
`;
