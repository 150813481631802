import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { ResponsiveImage } from '@web/molecules';
import {
  StyledGetSocial,
  StyledGetSocialHeader,
  Description,
  SocialIconsContainer,
  GetSocialContainer,
} from './styles.js';

export const GetSocial = ({ header, description, icons: socialIcons }) => {
  const renderSocialIcon = (
    { image: { src, alt }, href, opensNewTab },
    idx,
  ) => {
    return (
      <Link
        key={idx}
        href={href}
        target={opensNewTab ? '_blank' : '_self'}
        rel="noreferrer"
      >
        <ResponsiveImage src={src} alt={alt} height={24} width={24} />
      </Link>
    );
  };

  return (
    <GetSocialContainer>
      <StyledGetSocial>
        <StyledGetSocialHeader>{header}</StyledGetSocialHeader>
        {description && <Description>{description}</Description>}
        <SocialIconsContainer>
          {socialIcons.map((socialIcon, idx) =>
            renderSocialIcon(socialIcon, idx),
          )}
        </SocialIconsContainer>
      </StyledGetSocial>
    </GetSocialContainer>
  );
};
GetSocial.displayName = 'GetSocial';

GetSocial.propTypes = {
  header: PropTypes.string.isRequired,
  description: PropTypes.string,
  icons: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      image: PropTypes.object.isRequired,
      opensNewTab: PropTypes.string.isRequired,
    }),
  ),
};
