import React from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
  CheckmarkIcon,
  CheckmarkIconSimple,
  CheckmarkCircle,
} from '@ui/icons';
import { StyledListItem, ItemHeading, ItemBody, ItemIcon } from './styles.js';

export const ListItem = ({ heading, body, icon, iconColor }) => {
  const isIcon = icon !== undefined;
  return (
    <StyledListItem isIcon={isIcon}>
      {isIcon ? (
        <ItemIcon icon={icon}>
          {icon === 'bullet' && (
            <Icon
              iconName={icon}
              size="14px"
              alt=""
              id={`list_item_${heading}`}
            />
          )}
          {icon === 'checkmark' && <CheckmarkIcon size="17px" />}
          {icon === 'checkmarkSimple' && (
            <CheckmarkIconSimple color={iconColor} />
          )}
          {icon === 'checkmarkCircle' && <CheckmarkCircle color={iconColor} />}
        </ItemIcon>
      ) : (
        <ItemHeading>{heading}</ItemHeading>
      )}
      <ItemBody>{body}</ItemBody>
    </StyledListItem>
  );
};
ListItem.displayName = 'ListItem';
ListItem.propTypes = {
  heading: PropTypes.string,
  body: PropTypes.string.isRequired,
  icon: PropTypes.oneOf([
    'bullet',
    'checkmark',
    'checkmarkSimple',
    'checkmarkCircle',
  ]),
  iconColor: PropTypes.string,
};
