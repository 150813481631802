'use client';
import MuxPlayer from '@mux/mux-player-react';
import styled from 'styled-components';

export const StyledMuxPlayer = styled(MuxPlayer)`
  --media-object-fit: cover;
  --bottom-controls: none;
  --play-button: ${props => (!props.autoPlay ? 'block' : 'none')};
  &::part(center play button) {
    background-color: ${props => props.theme.colors.hero.spinach};
    padding: 22px 16px;
    min-width: 58px;
    border-radius: 58px;
  }
  aspect-ratio: 4 / 3;
  &::part(seek-forward),
  &::part(seek-backward) {
    display: none;
  }
`;
