import * as yup from 'yup';
import { SimplifiedHero } from '@web/components';
import { linkSchema } from '../schemas';

export const component = SimplifiedHero;

export const schema = yup
  .object({
    heading: yup.string(),
    description: yup.string(),
    link: linkSchema,
    backgroundColor: yup.string(),
    buttonVariant: yup.string(),
    level: yup.string(),
    eyebrow: yup.string(),
  })
  .transform(value => ({
    heading: value.heading,
    description: value.description,
    link: value.link?.[0],
    backgroundColor: value.backgroundColor,
    buttonVariant: value.button_variant || undefined,
    level: value.level,
    eyebrow: value.eyebrow,
  }));
