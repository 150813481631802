import * as yup from 'yup';
import { VideoHero } from '@web/components';
import {
  linkSchema,
  createInlineRichTextFromCMSSchema,
  inlineRichTextFromCMSSchema,
} from '../schemas';
import { schema as emailCaptureSchema } from './email_capture';
import { schema as muxVideoSchema } from './mux_video';

export const component = VideoHero;

export const schema = yup
  .object({
    eyebrowText: yup.string().nullable(),
    headline: yup.mixed().when('level', {
      is: val => !!val,
      then: yup.lazy((_, ctx) => {
        return createInlineRichTextFromCMSSchema(ctx.parent.level);
      }),
      otherwise: inlineRichTextFromCMSSchema, // Default schema if `level` is not provided
    }),
    subheadline: yup.string().nullable(),
    cta: yup.lazy(item => {
      switch (item?.component) {
        case 'email_capture':
          return emailCaptureSchema;
        default:
          return linkSchema;
      }
    }),
    backgroundColor: yup.string(),
    fontColor: yup.string(),
    horizontalOrientation: yup.mixed().oneOf(['left', 'center', 'right']),
    ctaColor: yup.string(),
    video: muxVideoSchema,
    level: yup.string().nullable(),
  })
  .transform(value => ({
    eyebrowText: value.eyebrow_text,
    headline: value.headline,
    subheadline: value.subheadline,
    cta: value.cta[0] || undefined,
    backgroundColor: value.background_color || undefined,
    fontColor: value.font_color || undefined,
    horizontalOrientation: value.horizontal_orientation,
    ctaColor: value.cta_color || undefined,
    video: value.video[0],
    level: value.level,
  }));
