import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage } from '@web/molecules';
import { PillText } from '@web/atoms';
import {
  StyledHoverImageCard,
  ImageContainer,
  HoverImage,
  Header,
  BodyContainer,
  Body,
  BadgeContainer,
} from './styles.js';

export const HoverImageCard = ({
  header,
  image,
  hoverImage = null,
  body,
  badge = null,
  level,
}) => {
  return (
    <StyledHoverImageCard>
      {badge && (
        <BadgeContainer>
          <PillText text={badge} variant="kale"></PillText>
        </BadgeContainer>
      )}

      <ImageContainer tabIndex={hoverImage ? 0 : -1}>
        <ResponsiveImage
          alt={image.alt}
          src={image.src}
          width={image.width}
          height={image.height}
        />

        {hoverImage && (
          <HoverImage>
            <ResponsiveImage
              alt={hoverImage.alt}
              src={hoverImage.src}
              width={image.width}
              height={image.height}
            />
          </HoverImage>
        )}
      </ImageContainer>
      <BodyContainer>
        <Header as={level}>{header}</Header>
        <Body>{body}</Body>
      </BodyContainer>
    </StyledHoverImageCard>
  );
};

HoverImageCard.displayName = 'HoverImageCard';

HoverImageCard.propTypes = {
  header: PropTypes.string.isRequired,
  body: PropTypes.node.isRequired,
  image: PropTypes.shape({
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    alt: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
  hoverImage: PropTypes.shape({
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    alt: PropTypes.string.isRequired,
  }),
  badge: PropTypes.string,
  level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
};
