import * as yup from 'yup';
import { ConfirmationBanner } from '@web/components';
import {
  linkSchema,
  richTextFromCMSSchema,
  requiredImageFromCMSSchema,
} from '../schemas';
import { schema as socialButtonSchema } from './social_button';

export const component = ConfirmationBanner;

export const schema = yup
  .object({
    component: yup.string().required(),
    image: requiredImageFromCMSSchema,
    header: yup.string().required(),
    description: richTextFromCMSSchema,
    cta: yup.array(
      yup.lazy(item => {
        switch (item.component) {
          case 'social_button':
            return socialButtonSchema.required();
          default:
            return linkSchema.notRequired();
        }
      }),
    ),
  })
  .transform(value => ({
    image: value.image,
    header: value.header,
    description: value.description,
    cta: value.cta,
    component: value.component,
  }));
