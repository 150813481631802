'use client';
import styled from 'styled-components';
import { ProductCardWrapper } from '@web/components/ProductSelector/styles';

export const StyledCollapsibleMenu = styled.div`
  border-radius: 32px;
  background-color: ${props => props.theme.colors.neutral.white};
`;

export const MenuButton = styled.div`
  max-height: 60px;
  display: flex;
  color: ${props => props.theme.colors.text.secondary};
  justify-content: space-between;
  align-items: center;
  ${props => props.theme.typography.button}
  padding: ${props => props.theme.spacing(5, 6)};
`;

export const IconContainer = styled.div`
  border-radius: 100px;
  min-width: 50px;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.background.base};
`;

export const ExpandedMenu = styled.div`
  ${ProductCardWrapper} {
    &:first-child {
      margin-left: ${props => props.theme.spacing(5)};
    }
  }
`;
