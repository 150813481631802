'use client';
import styled from 'styled-components';

export const StyledHoverImageCard = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const Header = styled.h4`
  display: inline-block;
  ${props => props.theme.fontFamily.condensed}
  ${props => props.theme.typography.heading5}
`;

export const HoverImage = styled.div`
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.3s ease-out;
`;

export const ImageContainer = styled.div`
  border-radius: ${props => props.theme.spacing(8)};
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 310px;
  margin: 0 auto;

  &:focus,
  &:hover {
    ${HoverImage} {
      opacity: 1;
      pointer-events: unset;
    }
  }
`;

export const BodyContainer = styled.div`
  font-size: 1rem;
  line-height: 1.5;
  margin: 30px auto 0;
  max-width: 300px;
  padding: 0 10px;
  text-align: center;
`;

export const Body = styled.div`
  display: inline;
`;

export const BadgeContainer = styled.div`
  display: flex;
  position: absolute;
  margin-top: 20px;
  margin-left: 20px;
  z-index: 1;
`;
