import React from 'react';
import PropTypes from 'prop-types';
import { ListItem } from '@web/atoms';
import { StyledList, ListHeader } from './styles.js';

export const List = ({ heading, items, icon, iconColor, gap }) => {
  return (
    <>
      {heading && <ListHeader>{heading}</ListHeader>}
      <StyledList $gap={gap}>
        {items.map((item, index) => (
          <ListItem
            heading={item.heading}
            body={item.body}
            icon={icon}
            key={index}
            iconColor={iconColor}
          />
        ))}
      </StyledList>
    </>
  );
};
List.displayName = 'List';
List.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      body: PropTypes.string,
    }),
  ).isRequired,
  heading: PropTypes.string,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  gap: PropTypes.number,
};
