'use client';
import styled from 'styled-components';

export const StyledHero = styled.div`
  background-color: ${props => props.theme.colors.get(props.backgroundColor)};
  position: relative;
  z-index: 2;
`;

export const ContentContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  justify-content: ${props => props.horizontalOrientation.justify};
  margin: 0 auto;
  width: 100%;

  @media ${props => props.theme.laptop} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.spacing(7, 6)};
  width: 100%;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media ${props => props.theme.tablet} {
    max-width: 496px;
    align-items: left;
  }
  @media ${props => props.theme.laptop} {
    align-items: start;
  }
`;

export const Header = styled.div`
  :is(h1, h2, h3, h4, h5, h6) {
    ${props => props.theme.typography.heading3}
  }
  color: ${props =>
    props.$fontColor && props.theme.colors.get(props.$fontColor)};
  text-align: center;
  @media ${props => props.theme.tablet} {
    margin-bottom: ${props => props.theme.spacing(4)};
    text-align: center;
    :is(h1, h2, h3, h4, h5, h6) {
      ${props => props.theme.typography.heading2}
    }
  }
  @media ${props => props.theme.laptop} {
    text-align: left;
  }
`;

export const Subheader = styled.p`
  ${props => props.theme.typography.heading5}
  margin: ${props => props.theme.spacing(4, 0)};
  color: ${props =>
    props.$fontColor && props.theme.colors.get(props.$fontColor)};
  width: 100%;
  text-align: center;
  @media ${props => props.theme.laptop} {
    margin-top: ${props => props.theme.spacing(0)};
    text-align: left;
    width: none;
  }
`;

export const EyebrowText = styled.p`
  ${props => props.theme.typography.subheading1}
  color: ${props =>
    props.$fontColor && props.theme.colors.get(props.$fontColor)};
  text-align: center;
  padding-bottom: ${props => props.theme.spacing(2)};
`;

export const ButtonWrapper = styled.div`
  margin: ${props => props.theme.spacing(5, 0, 2)};
  ${props => props.theme.getFocusOutline(props.$backgroundColor)}
  @media ${props => props.theme.tablet} {
    margin: ${props => props.theme.spacing(6, 0, 0)};
  }
`;

export const InputWrapper = styled.div`
  margin-top: ${props => props.theme.spacing(20)};
  width: 110%;
  margin-left: -5%;
  @media ${props => props.theme.tabletL} {
    margin-top: ${props => props.theme.spacing(10)};
  }
`;

export const VideoWrapper = styled.div`
  width: 100%;
  mux-player {
    max-height: 240px;
    @media ${props => props.theme.tablet} {
      max-height: 100%;
    }
  }
`;
